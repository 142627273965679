import { inject } from 'vue';
import { Emitter, Handler } from 'mitt';
import { TTrainingForward } from 'src/types/trainingType';

type TDemoLogin = {
  invalidATCode: undefined;
};
type TTrainingChatEvent = {
  startTraining: undefined;
  fetchTrainingForward: TTrainingForward;
  getResult: undefined;
  nextInnerQuestion: undefined;
};
type TTrainingChatHeaderEvent = {
  handleTrainingHeaderButtonDisable: boolean;
};
type TTrainingDialogEvent = {
  openSimpleCenterDialog: string;
  openSingleButtonCenterDialog: string;
  openTwoButtonCenterDialog: string;
  onExit: undefined;
};

type TPackingUpEvent = {
  clickPackingUpButton: number;
  addPackingUpAnswer: undefined;
  handlePackingUpButtonDisable: undefined;
  playRestartCountdown: undefined;
  handlePausedPackingUp: boolean;
  changeIsDialogOpen: boolean;
};
type TGuessPhotoViewpoint = {
  // 어디서 찍은 사진일까요 (9602)
  handleEachGuessPhotoViewpointResult: boolean;
};
type TFindLuggage = {
  // 여행 짐아 어디있니? (9303)
  handleFindLuggageResult: boolean;
  setNextQuestion: undefined;
  resetBoard: undefined;
};
type TFindVisitPath = {
  showAnswer: undefined;
};

type Events = {
  openAssessmentGuide: undefined;
  setTrainingListHeight: undefined;
  updateSubjective: undefined;
  sendMessage: string;
  userInputOnClick: undefined;
  userBirthOnClick: undefined;
  userGenderOnClick: undefined;
  userEducationOnClick: undefined;
  playAudio: string;
  setTrainingTimer: undefined;
  countUpTrainingQuestionCount: undefined;
  openExitDialog: undefined;
  openRetryDialog: string;
  sendPass: string;
  setQuestionEndTime: undefined;
  getTrainingResult: boolean;
  openCenterDialog: string;
  playCountdownLottie: undefined;
  handleCrosswordPuzzle: string;
  handleSequenceOrder: string;
  handleCorrectWhereAndWhat: undefined;
  handleIncorrectWhereAndWhat: undefined;
  handlePassWhereAndWhat: undefined;
  handleCorrectRotateLuggage: undefined;
  handleIncorrectRotateLuggage: undefined;
  handlePassRotateLuggage: undefined;
  handlePassAble: boolean;
  handleReceiptButtonDisabled: boolean;
  handleInputPassAble: boolean;
  micAccessInteraction: undefined;
  resetNoResponseTimer: undefined;
  updateQuestionStringIndex: number;
} & TDemoLogin &
  TTrainingChatEvent &
  TTrainingChatHeaderEvent &
  TTrainingDialogEvent &
  TPackingUpEvent &
  TGuessPhotoViewpoint &
  TFindLuggage &
  TFindVisitPath;
type Key = keyof Events;
export type TEvents = undefined | TTrainingForward | string | boolean | number;

export default function useMitt() {
  const mitt = inject('mitt') as Emitter<Events>;

  const on = (key: keyof Events, handler: Handler<Events[Key]>) =>
    mitt.on(key, handler);
  const off = (key: keyof Events, handler: Handler<Events[Key]>) =>
    mitt.off(key, handler);

  const clear = () => mitt.all.clear();

  const emit = (key: keyof Events, event: Events[Key]) => mitt.emit(key, event);

  return { on, off, emit, clear };
}
