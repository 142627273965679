import { boot } from 'quasar/wrappers';
import mitt from 'mitt';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export default boot(({ app, router }) => {
  const emit = mitt();
  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      // emit.all.clear();
      emit.off('openAssessmentGuide');
      emit.off('setTrainingListHeight');
      emit.off('updateSubjective');
      emit.off('sendMessage');
      emit.off('userInputOnClick');
      emit.off('userBirthOnClick');
      emit.off('userGenderOnClick');
      emit.off('userEducationOnClick');
      emit.off('countUpTrainingQuestionCount');
      emit.off('sendPass');
      emit.off('setQuestionEndTime');
      emit.off('getTrainingResult');
      emit.off('openCenterDialog');
      emit.off('playCountdownLottie');
      emit.off('handleCrosswordPuzzle');
      emit.off('handleSequenceOrder');
      emit.off('handleCorrectWhereAndWhat');
      emit.off('handleIncorrectWhereAndWhat');
      emit.off('handlePassWhereAndWhat');
      emit.off('handleCorrectRotateLuggage');
      emit.off('handleIncorrectRotateLuggage');
      emit.off('handlePassRotateLuggage');
      emit.off('handlePassAble');
      emit.off('handleInputPassAble');
      emit.off('micAccessInteraction');
      emit.off('resetNoResponseTimer');
      emit.off('updateQuestionStringIndex');
      emit.off('clickPackingUpButton');
      emit.off('addPackingUpAnswer');
      emit.off('handlePackingUpButtonDisable');
      emit.off('playRestartCountdown');
      emit.off('handlePausedPackingUp');
      emit.off('changeIsDialogOpen');

      // DemoLogin
      emit.off('invalidATCode');

      // ChatHeader
      emit.off('handleTrainingHeaderButtonDisable');
      emit.off('setTrainingTimer');

      // ChatMain
      emit.off('startTraining');
      emit.off('getResult');

      // ChatDialogs
      emit.off('openSimpleCenterDialog');
      emit.off('openSingleButtonCenterDialog');
      emit.off('openTwoButtonCenterDialog');
      emit.off('onExit');

      // FindTravelItems
      emit.off('resetBoard');

      next();
    }
  );

  app.use({
    install: (Vue) => {
      Vue.provide('mitt', emit);
    },
  });
});
